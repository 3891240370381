<template>
    <div>
      <section class="section section-new-form section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-info">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center align-items-center">

                <div class="col-lg-12">
                    <card gradient="secondary" shadow body-classes="p-lg-5">
                      <form
                ref="newPatientForm"
                @submit.prevent="validateBeforeSubmit"
              >
                            <h4 class="mb-1 display-4 text-center">New Patient Form - Women</h4>
                            <div class="row">
                              <div class="col-lg-12">
<base-input label="Date">
                        <flat-picker slot-scope="{blur}"
                                     @on-close="blur"
                                     :config="{allowInput: true}"
                                     class="form-control datepicker"
                                     v-model="patient.date">
                        </flat-picker>
                    </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
<base-input
                            v-model="patient.first_name"
                                        label="First Name"
                                        placeholder="First name"
                                        required
                                        :error="($v.patient.first_name.$error) ? 'First Name is required and must have min length of 4': ''"
                                        @input="$v.patient.first_name.$touch()"
                                        >
                            </base-input>
                              </div>
                              <div class="col-lg-6">
<base-input
                            v-model="patient.last_name"
                                        label="Last Name"
                                        placeholder="Last name"
                                        required
                                        :error="($v.patient.last_name.$error) ? 'Last Name is required and must have min length of 4': ''"
                                        @input="$v.patient.last_name.$touch()"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
<base-input
                            v-model="patient.email"
                                        label="Email address"
                                        placeholder="Email address"
                                        required
                                        :error="($v.patient.email.$error) ? 'Email is required': ''"
                                        @input="$v.patient.email.$touch()">
                            </base-input>
                              </div>
                              <div class="col-lg-6">
                                <base-input
                            v-model="patient.phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        >
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <!-- Checkboxes -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Location</small>
                    </div>
                    <div class="row">
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Full time in Bali" v-model="patient.location">
                        Full time in Bali
                      </base-checkbox>
                    </div>
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Part time in Bali" v-model="patient.location">
                        Part time in Bali
                    </base-checkbox>
                    </div>
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Overseas" v-model="patient.location">
                        Overseas
                    </base-checkbox>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row">
                              <div class="col-lg-12">
<base-input label="Date of Birth">
                        <flat-picker slot-scope="{blur}"
                                     @on-close="blur"
                                     :config="{allowInput: true}"
                                     class="form-control datepicker"
                                     v-model="patient.date_of_birth">
                        </flat-picker>
                    </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Which Health Care issue prompted you to make todays appointment?">
                                    <textarea v-model="patient.health_care_issues" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Are you facing any other Health Care issues you would like to address?">
                                    <textarea v-model="patient.health_care_issues_to_address" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Briefly describe the treatment history of your main health care concern: For example; What type of Practitioners you have seen (if any), what medications or supplements you may have tried in the past etc.">
                                    <textarea v-model="patient.health_care_concern" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Please list/date any hospitalisations, surgeries or past major illnesses">
                                    <textarea v-model="patient.past_illness" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Briefly describe and date traumatic events in your life: Examples include; deaths, relationship breakdowns, job loss, bankruptcy, etc.">
                                    <textarea v-model="patient.traumatic_events" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Have you had any past exposure to toxic chemicals (as used in farming, building, photography etc.) or dangerous materials?">
                                    <textarea v-model="patient.toxic_chemicals" class="form-control" name="toxic_chemicals" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Please list any Medications or Supplements you are currently taking.">
                                    <textarea v-model="patient.medications_taking" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Do you follow a restricted diet, now or in the past?">
                                    <textarea v-model="patient.restricted_diet" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.energy_level"
                                        label="Please rate your perceived energy level on a scale of 1-10 (10 is highest)."
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.stress_level"
                                        label="Please rate on a scale of 1-10 your current level of stress (10 is highest)."
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.sleep_hours"
                                        label="Approximately how many hours do you sleep per night?"
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.exercise"
                                        label="How many times per week do you exercise?"
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">

                    <!-- Radio buttons -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">How often do you drink alcohol?</small>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <base-radio class="mb-3" name="Daily" v-model="patient.alcohol">
                        Daily
                    </base-radio>

                    <base-radio class="mb-3" name="Weekly" v-model="patient.alcohol">
                        Weekly
                    </base-radio>
                    <base-radio class="mb-3" name="Binge" v-model="patient.alcohol">
                        Binge
                    </base-radio>
                      </div>
                      <div class="col-6">
                        <base-radio class="mb-3" name="3-4 times per week" v-model="patient.alcohol">
                        3-4 times per week
                    </base-radio>
                    <base-radio class="mb-3" name="Occasionally" v-model="patient.alcohol">
                        Occasionally
                    </base-radio>
                    <base-radio class="mb-3" name="Never" v-model="patient.alcohol">
                        Never
                    </base-radio>
                      </div>
                    </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.smoker"
                                        label="Are you a smoker?"
                                        placeholder="e.g yes"
                                        >
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <!-- Checkboxes -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Which emotions predominate for you?</small>
                    </div>
                    <div class="row">
                      <div class="col-6">
<base-checkbox class="mb-3" name="Worry" v-model="patient.emotions">
                        Worry
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Sadness/Grief" v-model="patient.emotions">
Sadness/Grief                    </base-checkbox>

                    <base-checkbox class="mb-3" name="Depression" v-model="patient.emotions">
                        Depression
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Fear" v-model="patient.emotions">
                        Fear
                    </base-checkbox>
                      </div>
                      <div class="col-6">
                        <base-checkbox class="mb-3" name="Happy" v-model="patient.emotions">
                        Happy
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Anger / Frustration" v-model="patient.emotions">
                        Anger / Frustration
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Anxiety" v-model="patient.emotions">
                        Anxiety
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Other" v-model="patient.emotions">
                        Other
                    </base-checkbox>
                    <base-input
                            v-model="patient.emotions_other"
                                        placeholder="e.g Other"
                                        >
                            </base-input>
                      </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                              <div class="col-12">
                                <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Menstrual History</small>
                    </div>
                                <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Contraceptive History.</small>
                    </div>
<base-input class="mb-4" label="Do you use contraception or have you used in the past?
If so what form?
How long have you or did you use it?
Do you experience any positive or negative symptoms using this form of contraception?
Any other reasons you have used contraception now or in the past (improved skin etc)">
                                    <textarea v-model="patient.contraceptions" class="form-control" name="contraception" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">

                    <!-- Radio buttons -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Are your periods....</small>
                    </div>
                    <base-radio class="mb-3" name="Regular" v-model="patient.periods">
                        Regular
                    </base-radio>

                    <base-radio class="mb-3" name="Irregular - longer than 28 days" v-model="patient.periods">
                        Irregular - longer than 28 days
                    </base-radio>
                    <base-radio class="mb-3" name="Irregular - shorter than 28 days" v-model="patient.periods">
                        Irregular - shorter than 28 days
                    </base-radio>
                    <base-radio class="mb-3" name="No longer menstruating - Please answer following questions based on your menstrual history." v-model="patient.periods">
                        No longer menstruating - Please answer following questions based on your menstrual history.
                    </base-radio>
                    <base-radio class="mb-3" name="Other" v-model="patient.periods">
                        Other
                    </base-radio>
                    <base-input
                            v-model="patient.periods_other"
                                        placeholder="e.g Other"
                                        >
                            </base-input>

                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">

<base-input class="mb-4" label="If you experience PMS please describe your associated symptoms: If not please progress to the next question.">
                                    <textarea v-model="patient.experience_pms" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">

                    <!-- Radio buttons -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Please indicate the quality of your period.</small>
                    </div>
                    <div class="row">

                      <div class="col-lg-6">

                      <base-checkbox class="mb-3" name="Heavy (have to change tampon in less than 4 hours)" v-model="patient.period_quality">
                        Heavy (have to change tampon in less than 4 hours)
                    </base-checkbox>

                    <base-checkbox class="mb-3" name="Light" v-model="patient.period_quality">
                        Light
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Have some brown menstrual blood" v-model="patient.period_quality">
                        Have some brown menstrual blood
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Menstrual blood is dark red" v-model="patient.period_quality">
                        Menstrual blood is dark red
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Clotting" v-model="patient.period_quality">
                        Clotting
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Pain" v-model="patient.period_quality">
                        Pain
                    </base-checkbox>

                    </div>
                    <div class="col-lg-6">
                    <base-checkbox class="mb-3" name="Back aches with period" v-model="patient.period_quality">
                        Back aches with period
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Accompanying dragging sensation in the abdomen" v-model="patient.period_quality">
                        Accompanying dragging sensation in the abdomen
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Experience fatigue with period" v-model="patient.period_quality">
                        Experience fatigue with period
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="PMS with my cycle" v-model="patient.period_quality">
                        PMS with my cycle
                    </base-checkbox>
                    <base-checkbox class="mb-3" name="Experience dizziness with my period" v-model="patient.period_quality">
                        Experience dizziness with my period
                    </base-checkbox>

                    </div>

                    </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
                                <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Do you experience any of the the following symptoms:</small>
                    </div>
                    <div class="table-responsive">
                    <table class="table">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col" class="text-center">Daily</th>
      <th scope="col" class="text-center">Weekly</th>
      <th scope="col" class="text-center">Occasionally</th>
      <th scope="col" class="text-center">In the Past</th>
      <th scope="col" class="text-center">Never</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(itm,index) in symptoms" :key="index">
      <th scope="row"> {{itm.name}}</th>
      <td align="center"><base-checkbox class="mb-3" style="margin:-15px" name="Daily" v-model="symptoms[index].routine"></base-checkbox></td>
      <td align="center"><base-checkbox class="mb-3" style="margin:-15px" name="Weekly" v-model="symptoms[index].routine"></base-checkbox></td>
      <td align="center"><base-checkbox class="mb-3" style="margin:-15px" name="Occasionally" v-model="symptoms[index].routine"></base-checkbox></td>
      <td align="center"><base-checkbox class="mb-3" style="margin:-15px" name="In the Past" v-model="symptoms[index].routine"></base-checkbox></td>
      <td align="center"><base-checkbox class="mb-3" style="margin:-15px" name="Never" v-model="symptoms[index].routine"></base-checkbox></td>
    </tr>
  </tbody>
                    </table>
</div>

                              </div>
                            </div>

                            <base-alert type="success" icon="ni ni-like-2" dismissible v-if="success">
                <span slot="text"><strong>Sent!</strong> We have recieved your email. Please check your email for further details!</span>
            </base-alert>
            <modal :show.sync="success"
                   gradient="primary"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Email Sent Successfully!</h4>
                    <p>We have recieved your email. Please check your email for further details!</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="success = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="success = false">
                        Close
                    </base-button>
                </template>
            </modal>
            <base-alert type="danger" icon="ni ni-support-16" dismissible v-if="error">
                <span slot="text"><strong>Error!</strong> Some error is observed, please try again. Apologies for the inconvenience</span>
            </base-alert>
            <modal :show.sync="error"
                   gradient="danger"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Apologies for the inconvinance!</h4>
                    <p>Some error is observed, please try again. Apologies for the inconvenience</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="error = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="error = false">
                        Close
                    </base-button>
                </template>
            </modal>
            <base-alert type="danger" icon="ni ni-support-16" v-if="$v.$invalid">
                <span slot="text"><strong>Form Invalid!</strong> Please fill the form Properly.</span>
            </base-alert>
                            <base-button type="info" round block size="lg" native-type="submit" :disabled="$v.$invalid">
                                Submit
                            </base-button>
                      </form>
                        </card>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required, email, minLength } from 'vuelidate/lib/validators'
export default {
  metaInfo: {
    title: 'New Patient Women'
  },
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/components/Modal.vue'),
    flatPicker
  },
  name: 'new-patient-women',
  data () {
    return {
      checkboxes: {
        unchecked: false,
        checked: true,
        uncheckedDisabled: false,
        checkedDisabled: true
      },
      picked: null,
      radio: {
        radio1: 'radio1',
        radio2: 'radio3'
      },
      switches: {
        off: false,
        on: true
      },
      sliders: {
        slider1: 0,
        slider2: [150, 400]
      },
      dates: {
        simple: '2018-07-17',
        range: '2018-07-17 to 2018-07-19'
      },
      success: false,
      error: false,
      patient: {
        date: new Date().toISOString().slice(0, 10),
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        location: [],
        date_of_birth: null,
        health_care_issues: null,
        health_care_issues_to_address: null,
        health_care_concern: null,
        past_illness: null,
        traumatic_events: null,
        toxic_chemicals: null,
        medications_taking: null,
        restricted_diet: null,
        energy_level: null,
        stress_level: null,
        sleep_hours: null,
        exercise: null,
        alcohol: null,
        smoker: null,
        emotions: [],
        emotions_other: null,
        contraceptions: null,
        periods: null,
        periods_other: null,
        experience_pms: null,
        period_quality: []
      },
      symptoms: [
        {
          name: 'Itchy, red, gritty or dry eyes',
          routine: []

        }, {
          name: 'Blurred or poor eyesight',
          routine: []
        }, {
          name: 'Neck, shoulder pain',
          routine: []
        }, {
          name: 'Discomfort under the ribs',
          routine: []
        }, {
          name: 'Reflux/Stomach Ulcers',
          routine: []
        }, {
          name: 'Gallstones',
          routine: []
        }, {
          name: 'Grinding Teeth/Jaw tension/TMJ',
          routine: []
        }, {
          name: 'High Blood Pressure',
          routine: []
        }, {
          name: 'Low Blood Pressure',
          routine: []
        }, {
          name: 'Shingles/Warts/Herpes',
          routine: []
        }, {
          name: 'Wake frequently between 1-3 am',
          routine: []
        }, {
          name: 'Sluggish in the morning',
          routine: []
        }, {
          name: 'How often do you drink coffee',
          routine: []
        }, {
          name: 'Dislike the heat',
          routine: []
        }, {
          name: 'Palms of hands and/or soles of feet burn or feel hot',
          routine: []
        }, {
          name: 'Facial flushing and/or redness',
          routine: []
        }, {
          name: 'Itchy / burning skin / rashes',
          routine: []
        }, {
          name: 'Nose bleeds',
          routine: []
        }, {
          name: 'Bleeding gums, ulcers',
          routine: []
        }, {
          name: 'Sore throat',
          routine: []
        }, {
          name: 'Difficulty falling asleep',
          routine: []
        }, {
          name: 'Night sweats',
          routine: []
        }, {
          name: 'Restless sleep',
          routine: []
        }, {
          name: 'Spontaneous sweating',
          routine: []
        }, {
          name: 'Thirst',
          routine: []
        }, {
          name: 'Bad breath',
          routine: []
        }, {
          name: 'Strong appetite',
          routine: []
        }, {
          name: 'Weakness or aching of the lower back',
          routine: []
        }, {
          name: 'Constant fatigue',
          routine: []
        }, {
          name: 'Incontinence',
          routine: []
        }, {
          name: 'Frequent urination',
          routine: []
        }, {
          name: 'Wake at night to urinate',
          routine: []
        }, {
          name: 'Low sex drive',
          routine: []
        }, {
          name: 'Exhausted after exercise',
          routine: []
        }, {
          name: 'Cold hands and feet',
          routine: []
        }, {
          name: 'Dislike of cold weather',
          routine: []
        }, {
          name: 'Poor appetite',
          routine: []
        }, {
          name: 'Lack of thirst',
          routine: []
        }, {
          name: 'Put on weight easily',
          routine: []
        }, {
          name: 'Fluid retention',
          routine: []
        }, {
          name: 'Phlegm in back of throat',
          routine: []
        }, {
          name: 'Loose stools',
          routine: []
        }, {
          name: 'Constipation',
          routine: []
        }, {
          name: 'Poor sense of taste and smell',
          routine: []
        }, {
          name: 'Asthma',
          routine: []
        }, {
          name: 'Cough',
          routine: []
        }, {
          name: 'Sinus Infections/Congestion',
          routine: []
        }, {
          name: 'Lung Infections/Pneumonia',
          routine: []
        }, {
          name: 'Catch a cold and / or flu',
          routine: []
        }
      ]
    }
  },
  validations: {
    patient: {
      first_name: {
        required,
        minLength: minLength(4)
      },
      last_name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async validateBeforeSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        console.log('Form valid')
        this.saveForm()
      }
    },
    async saveForm () {
      const loader = this.$loading.show({ container: this.$refs.newPatientForm })
      try {
        const response = await window.axios.post('/api/forms/new-patient-women', {
          data: this.patient,
          symptoms: this.symptoms
        })
        loader.hide()
        this.success = true
        console.log(response.data.ResponseHeader.ResponseMessage)
      } catch (error) {
        if (error) {
          this.error = true
          loader.hide()
          console.log('Some Error Happened')
        }
      }
    }
  }
}
</script>
<style scoped>
</style>
